import request from './config'

// ! 获取客户人群
export function getCrowdList (params) {
  return request.get('/adqCrowdPackage/getCustomAudiences', { params })
}

// ! 获取商品
export function getProductionList (params) {
  return request.get('put/adqTools/getProductItems', { params })
}

// ! 获取商品库

export function getProductCatalogs (params) {
  return request.get('/adqTools/getProductCatalogs', { params })
}

// ! 获取用户行为数据源

export function getUserActionSets (params) {
  return request.get('/adqTools/userActionSets', { params })
}
// ! 获取落地页

export function getLandingPage (params) {
  return request.get('/adqTools/getPages', { params })
}

// ! 获取广告文案

export function getCreativeToolsText (params) {
  return request.get('/adqTools/getCreativeToolsText', { params })
}

// ! 获取定向包

export function getAdqAudience (params) {
  return request.get('/adqAudience', { params })
}

// ! 新增定向包
export function adqAudience (params) {
  return request.post('/adqAudience', params)
}

// !修改定向包
export function editAdqAudience (ids, params) {
  return request.put(`/adqAudience/${ids}`, params)
}

// ! 删除定向包

export function deleteAdqAudience (ids) {
  return request.delete(`/adqAudience/${ids}`)
}

// !生成视频封面图

export function addVideocaptures (params) {
  return request.post('/adqTools/addVideocaptures', params)
}

// !获取模块计划数据
export function getAdqPlan (params) {
  return request.get('/adqPlan', { params })
}

// !获取模块对应计划详情
export function getPlanDetails (params) {
  return request.get('/adqPlan/getPlanDetails', { params })
}

// ! 获取地域列表
export function getTargetingTags (params) {
  return request.get('/adqTools/getTargetingTags', { params })
}

// ! 新增品牌形象

export function addAdqBrand (params) {
  return request.post('/adqBrand', params)
}

// ! 获取品牌形象

export function getAdqBrandList (params) {
  return request.get('/adqBrand', { params })
}

// ! 删除品牌形象

export function delAdqBrand (id) {
  return request.delete(`/adqBrand/${id}`)
}

// !保存模块数据

export function saveAdqTemplate (params) {
  return request.post('/adqTemplate', params)
}

// ! 获取模板数据

export function getAdqTemplateList (params) {
  return request.get('/adqTemplate/getTemplate', { params })
}

// ! 修改模板数据

export function editAdqTemplate (id, params) {
  return request.put(`/adqTemplate/modifyTemplate/${id}`, params)
}

// ! 生成广告数据

export function uploadAdqTemplate (params) {
  return request.post('/adqTemplate/uploadTemplate', params)
}

// ! 获取广告详情

export function getAdDetail (params) {
  return request.get('/adqGroup/getGroupDetails', { params })
}

// ! 获取创意详情
export function getCreativeDetail (params) {
  return request.get('/adqCreative/getCreativeDetails', { params })
}

// ! 获取所有详情计划

export function getDetailInfo (params) {
  return request.get('/adqAds/getAdDetails', { params })
}

// !删除模板数据

export function delTemplateData (templateId) {
  return request.delete(`/adqTemplate/deleteTemplate/${templateId}`)
}

// ! 模板数据详情

export function getTemplateDetail (params) {
  return request.get('/adqTemplate/getTemplateDetails', { params })
}

// ! 获取adq列表
export function getADQList (params) {
  return request.get('/adqAccountRemark', { params })
}

// ! 批量修改adq账号信息

export function batchEditADQRemark (ids, { remark }) {
  return request.put(`/adqAccountRemark/${ids}?remark=${remark}`)
}
// ! 读取txt文件内容
export function getTXTFileInfo (params) {
  return request.post('/adqAudience/readTxtFile', params)
}

// ! 获取微信MP账号列表

export const getWxAccountList = (params) => request.get('/wxMpAccount', { params })

// ! 获取微信推广页模块数据

export const getWxPromotionPageList = (params) => request.get('/wxMpTemplate', { params })

// ! 新增推广页模块数据

export const addWxPromotionPage = (params) => request.post('/wxMpTemplate', params)

// ! 修改推广页模块数据

export const editWxPromotionPage = (id, params) => request.put(`/wxMpTemplate/${id}`, params)

// ! 删除推广页模块数据

export const deleteWxPromotionPage = (templateId) => request.delete(`/wxMpTemplate/deleteTemplate/${templateId}`)

// ! 获取主体列表

export const getSubject = (params) => request.get('/wxMpAccount/getSubject', { params })

// ! 获取腾讯对应账号下的推广页列表数据

export const getWxMpPages = (params) => request.get('/wxMpPages/getWxMpPages', { params })

export const getWxPageDetails = (params) => request.get('/wxMpTemplate/getTemplateDetails',{params})


// ! 获取模板详情数据

export const getTemplateDetails = (params) => request.get('/wxMpPlanTemplate/getTemplateDetails', { params })
export const delWXProjectItems = (id) => request.delete(`/wxMpPlanTemplate/deleteWxSinglePlan/${id}`)
// ! 获取模板编辑详情数据

export const getEditTemplateDetails = (params) => {
  return request.get('/wxMpTemplate/editTemplateDetail', { params })
}

// ! 删除单个推广页素材

export const delPerPromotionItem = (tagId) => request.delete(`/wxMpPages/deletePages/${tagId}`)

// ! 获取投放计划列表

export const getWxPlanList = (params) => request.get('/wxMpPlanTemplate', { params })

// ! 新增投放计划

export const addWxPlan = (params) => request.post('/wxMpPlanTemplate', params)

// ! 编辑投放计划

export const editWxPlan = (id,params) => request.put(`/wxMpPlanTemplate/${id}`, params)


// ! 获取计划模板数据

export const getWxEditPlanData = (params) => request.get('/wxMpPlanTemplate/editTemplateDetail',{params})

// ! 删除投放计划

export const delWxPlan = (id) => request.delete(`/wxMpPlanTemplate/${id}`,)

// ! 获取微信计划客户人群

export const getWxProjectCrowdList = (params) => request.get('/wxMpTool/getCustomAudiences',{params})

// ! 获取微信投放计划商品库

export const getWxProjectProducts = (params) => request.get('/wxMpTool/getProductCatalogs' ,{params})

// ! 获取微信投放计划商品

export const getWxProjectProductItem = (params) => request.get('/wxMpTool/getProductItems',{params})


// ! 获取微信广告标签

export const getWxProjectAdLabels = (params) => request.get('/wxMpTool/getWechatAdLabels',{params})


// ! 获取定向包

export const getPackAgesList = (params) => request.get('/wxMpAudience',{params})

// ! 保存定向包

export const  savePackAge = (params) => request.post('/wxMpAudience',params)

// ! 删除微信投放定向包

export const deletePackAge  = (id) => request.delete(`/wxMpAudience/${id}`)
