<template>
    <el-dialog
        title="选择主体"
        top="20px"
        append-to-body
        width="800px"
        :visible.sync="show"
        :before-close="handleClose"
    >
        <div class="principal-part">
            <dy-table
                :dataList="dataList"
                :rowList="rowList"
                height="500px"
                :showPagination="true"
                :pageSize="pageSize"
                :page="page"
                :total="total"
                :loading="loading"
                @page-change="handlePage"
            >
                <template #filter>
                    <c-input v-model="search" style="width:260px" placeholder="请输入主体名称">
                        <el-button slot="append" icon="el-icon-search" @click="handlePage(1)"></el-button>
                    </c-input>
                </template>
                <template #createdTime="{row}">
                  <span>{{formatTime(row.createdTime,'yyyy-mm-dd hh:MM:ss')}}</span>
                </template>
                <template #operation="{row}">
                    <el-button class="button-small" @click="handleLink(row)" type="primary">关联</el-button>
                </template>
            </dy-table>
        </div>
    </el-dialog>
</template>

<script>
import { getSubject } from '@/api/tencent.js'
import { formatTime } from '@/assets/js/utils'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      page: 1,
      total: 0,
      type: '',
      search: '',
      pageSize: 15,
      loading: false,
      dataList: [],
      formatTime,
      rowList: [{
        label: 'id',
        prop: 'id',
        colWidth:'100',
      }, {
        label: '主体名称',
        prop: 'accountName',
        colWidth:'200',
      }, {
        label: '创建时间',
        prop: 'createdTime',
        slot: true
      },{
          label:'操作',
          action:'operation',
          
      }]
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.initData()
        }
      }
    }
  },
  methods: {
    initData () {
      this.loading = true
      getSubject({
        page: this.page,
        pageSize: this.pageSize,
        accountName: this.search,
        type: this.type
      }).then(res => {
        this.dataList = res.list
        this.total = res.total
      }).finally(()=>{
        this.loading = false 
      })
    },
    handleLink (row) {
      this.$emit('confirm', row)
      this.$emit('close')
    },
    handleClose () {
      this.$emit('close')
    },
    handlePage (page) {
      this.page = page
      this.initData()
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
</style>
