<template>
  <el-dialog
    title="选择素材模板"
    append-to-body
    :visible.sync="show"
    :before-close="handleClose"
    width="1200px"
    top="20px"
  >
    <div class="template-container">
      <dy-table
        :dataList="dataList"
        :rowList="rowList"
        ref="AccTable"
        refName="subTable"
        height="600px"
        :showPagination="true"
        :pageSize="pageSize"
        :page="page"
        :total="total"
        :loading="loading"
        :isUseCheckbox="multiSelect"
        @pageChange="handlePage"
        @selectionChange="handleSelection"
        @row-click="handleRowClick"
      >
        <template #filter>
          <c-input v-model="pageName" style="width:260px" placeholder="请输入模板名称!">
            <el-button slot="append" icon="el-icon-search" @click="handlePage(1)"></el-button>
          </c-input>
          <span v-if="tips" style="margin-left:15px;color:#ccc;font-size:13px">{{tips}}</span>
        </template>
        <template #lastModifiedTime="{ row }">
          <span>{{ formatTime(row.createdTime * 1000, 'yyyy-MM-dd hh:mm:ss') }}</span>
        </template>

        <template #operation="{ row }">
          <el-button class="button-small" @click="handleLink(row)" type="primary">关联</el-button>
        </template>
      </dy-table>
    </div>
    <div slot="footer" v-if="multiSelect === true">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { formatTime } from '@/assets/js/utils'
import { getWxMpPages } from '@/api/tencent.js'

const TYPE_LIST = [
  {
    en: 'CAROUSEL',
    zn: '轮播图'
  },
  {
    en: 'IMAGE',
    zn: '图片'
  },
  {
    en: 'VIDEO',
    zn: '视频'
  }
]

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: [String, Number],
      default: ''
    },
    excludeInfo: {
      type: Array,
      default: ()=>([])
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    filterCallback: {
      type: [Function, Number],
      default: null
    },
    tips:{
      type:String,
      default:''
    }
  },
  data () {
    return {
      page: 1,
      total: 0,
      formatTime,
      pageSize: 15,
      loading: false,
      pageName: '',
      dataList: [],
      section: [],
      rowList: [{
        label: '推广页名称',
        prop: 'pageName',
        colWidth: '240'
      }, {
        label: '顶部素材',
        prop: 'resourcePreview',
        popover: true,
        mediaType: 'image'
      }, {
        label: '类型',
        prop: 'type'
      }, {
        label: '顶部素材数量',
        prop: 'resourceNumber',
        colWidth: '120'
      }, {
        label: '适用推广目标',
        prop: 'target'
      }, {
        label: '最后修改时间',
        prop: 'lastModifiedTime',
        slot: true
      }, {
        label: '操作',
        action: 'operation',
        slot: true
      }]
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV === true) {
          this.initData()
        }
      }
    }
  },
  methods: {
    initData () {
      this.loading = true
      this.dataList = []
      getWxMpPages({
        page: this.page,
        pageSize: this.pageSize,
        pageName: this.pageName,
        id: this.id,
        type: this.type
      }).then(res => {
        res.list = res.list.map(item => {
          return {
            ...item,
            target: '推广公众号',
            type: TYPE_LIST.find(typeItem => typeItem.en === item.page_elements_spec_list[0].elementType).zn,
            resourceNumber: item.page_elements_spec_list[0].videoSpec ? 1 : item.page_elements_spec_list[0].imageSpec.imageIdList.length,
            resourcePreview: item.videoPreviewUrl || item.imagePreviewUrl
          }
        })
        if (this.filterCallback) {
          res.list = res.list.filter(this.filterCallback)
        }
        if (this.excludeInfo.length > 0) {
          this.dataList = res.list.filter(item => {
            return !this.excludeInfo.includes(item.page_elements_spec_list[0].elementType)
          })
        } else {
          this.dataList = res.list
        }
        this.total = res.total_number
      }).finally(() => {
        this.loading = false
      })
    },
    handleClose () {
      this.page = 1
      this.$emit('close')
    },
    handleLink (row) {
      this.$emit('confirm', row)
      this.page = 1
      this.$emit('close')
    },
    handlePage (page) {
      this.page = page
      this.initData()
    },
    handleConfirm () {
      this.$emit('chooseSelection', this.section)
      this.$emit('close')
    },
    handleRowClick (row) {
      return this.$refs.AccTable.$refs.subTable.toggleRowSelection(row)
    },
    handleSelection (selection) {
      this.section = selection
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
/deep/ img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
}
</style>
