<template>
  <el-dialog
    title="模板详情"
    append-to-body
    :visible.sync="show"
    width="1200px"
    :before-close="handleClose"
    top="100px"
  >
    <div style="max-height:700px">
      <dy-table height="500px" :dataList="dataList" :rowList="rowList" :loading="loading">
        <template #filter>
         <el-tooltip content="查看更新状态!" placement="top">
            <el-button @click="refreshData">刷新数据</el-button>
         </el-tooltip>
        </template>
        <template #status="{ row }">
          <el-tooltip v-if="row.msg" :content="row.msg" placement="top">
            <el-tag :type="getType(row.status).type">{{ getType(row.status).text }}</el-tag>
          </el-tooltip>
          <el-tag :type="getType(row.status).type" v-else>{{ getType(row.status).text }}</el-tag>
        </template>
        <template #createdTime="{ row }">
          <span>{{ formatTime(row.createdTime, 'yyyy-MM-dd hh:mm:ss') }}</span>
        </template>
        <template #operation="{ row }">
          <c-button
            class="button-small"
            type="danger"
            :autoConfirm="true"
            @confirm="(cb) => deleteItem(row, cb)"
          >删除</c-button>
        </template>
      </dy-table>
    </div>
  </el-dialog>
</template>

<script>
import { getWxPageDetails, delPerPromotionItem } from '@/api/tencent.js'
import { formatTime } from '@/assets/js/utils'
export default {
  props: {
    templateId: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataList: [],
      loading: false,
      formatTime,
      rowList: [{
        label: '主体',
        prop: 'subjectType',
        colWidth: '220'
      }, {
        label: '公众号',
        prop: 'channelName'
      }, {
        label: '推广页模板名称',
        prop: 'pageName'
      }, {
        label: '图片展示',
        prop: 'previewUrl',
        mediaType: 'image',
        popover: true
      }, {
        label: '状态',
        prop: 'status',
        colWidth: '80',
        slot: true
      }, {
        label: '顶图类型',
        prop: 'materialTypeName',
        colWidth: '80'
      }, {
        label: '创建时间',
        prop: 'createdTime',
        colWidth: '200',
        slot: true
      }, {
        label: '操作',
        action: 'operation',
        colWidth: '70px'
      }]
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.getDetail()
        }
      }
    }
  },
  methods: {
    getDetail () {
      getWxPageDetails({
        page: 1,
        pageSize: 9999,
        templateId: this.templateId
      }).then(res => {
        res.list.forEach(item => {
          item.previewUrl = [1, 3].includes(item.materialType) ? item.materials[0] : item.keyFrameImageUrl[0]
        })
        this.dataList = res.list
      })
    },
    handleClose () {
      this.$emit('close')
    },
    deleteItem (row, cb) {
      delPerPromotionItem(row.tag).then(() => {
        this.getDetail()
      }).finally(() => {
        cb()
      })
    },
    getType (status) {
      if (status === 1) {
        return {
          type: 'info',
          text: '未上传'
        }
      }
      if (status === 2) {
        return {
          type: 'warning',
          text: '待上传'
        }
      }
      if (status === 3) {
        return {
          type: 'success',
          text: '已上传'
        }
      }
      if (status === 4) {
        return {
          type: 'danger',
          text: '错误'
        }
      }
      return {
        type: 'info',
        text: '未知'
      }
    },
    refreshData () {
      this.getDetail()
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
/deep/ .el-table__body-wrapper {
  overflow: auto !important;
}
</style>
