<template>
  <el-dialog
    :title="`${isEdit ? '编辑' : '新增'}模板`"
    :visible.sync="show"
    append-to-body
    :close-on-click-modal="false"
    width="1200px"
    top="20px"
    @close="handleCancel"
  >
    <div class="add-promotion-container" v-loading="containerLoading">
      <div class="operation">
        <h3>选择账号：</h3>
        <el-button type="primary" @click="handleChooseAccount">选择账号</el-button>
        <h3>素材模式：</h3>
        <el-radio-group v-model="mode" style="margin-left:10px">
          <el-radio :label="1">单一</el-radio>
          <el-tooltip content="所有账号套用相同的素材，建议在选择账号之后在进行操作！" placement="top">
            <el-radio :label="2">统一</el-radio>
          </el-tooltip>
        </el-radio-group>
        <template v-if="mode === 2">
          <el-button style="margin-left:10px" @click="localUploadFile">本地</el-button>
          <el-button type="warning" @click="chooseUniteWithLibrary">素材库</el-button>
          <input
            type="file"
            ref="localInput"
            @change="handleLocalFileChange"
            multiple
            style="display:none"
          />
        </template>
        <span style="font-size:14px;color:#ccc;margin-left:20px">（图片<=3MB，视频<=100MB）</span>
      </div>
      <div class="account-list">
        <el-tabs type="card" style="max-height: 600px;" editable @tab-remove="removeTab">
          <el-tab-pane
            v-for="(account,index) in  chooseAccountList"
            :key="index"
            :label="account[0]"
            :name="`${index}`"
          >
            <div class="account-item" v-for="(item,subIndex) in  account[1]" :key="item.targetId">
              <div class="account-info">
                <div class="account-info-title">
                  {{ item.channelName }}
                  <span
                    v-if="item.templateInfo"
                  >(类型:{{ item.templateInfo.type }} - 数量:{{ item.templateInfo.resourceNumber }})</span>
                  <el-tooltip content="请保证上传的素材数量与推广页模板顶部素材数量保持合理的比例关系!!!" placement="top">
                    <i class="el-icon-info" style="margin-left:10px"></i>
                  </el-tooltip>
                </div>
                <div>
                  <span v-if="item.principalPart">主体：{{ item.principalPart.accountName }}</span>
                  <el-button
                    type="info"
                    class="button-small"
                    v-else
                    @click="choosePrincipalPart(item)"
                  >选择主体</el-button>
                </div>
                <div class="flex-row-start-center">
                  <span
                    style="margin-right:15px;display:inline-block;width:180px;"
                    class="line-overflow"
                    v-if="item.templateInfo"
                    :title="item.templateInfo.pageName"
                  >模板：{{ item.templateInfo.pageName }}</span>
                  <el-button
                    v-else
                    style="margin-right:15px"
                    class="button-small"
                    type="warning"
                    @click="chooseTemplate(item)"
                  >选择模板</el-button>
                  <el-button
                    v-if="item.templateInfo"
                    class="button-small"
                    @click="chooseTemplate(item)"
                  >修改</el-button>
                </div>
              </div>
              <div class="account-material">
                <div class="material-list">
                  <div class="add-material" @click="localUpload(index, subIndex, item)">
                    <i class="el-icon-plus" style="font-size:20px"></i>
                    <input
                      type="file"
                      style="display:none"
                      :ref="`uploadInput`"
                      multiple
                      @change="handleFileChange"
                    />
                  </div>
                  <template v-if="isValidArray(item.materialList)">
                    <div
                      class="material-item"
                      v-for="(material,i) in item.materialList"
                      :key="material.id"
                    >
                      <img
                        v-if="IMAGE_TYPES.includes(material.format) && material.url"
                        :src="material.url"
                      />
                      <img
                        v-else-if="VIDEO_TYPES.includes(material.format) && material.url"
                        :src="`${material.url}?vframe/jpg/offset/0`"
                      />
                      <div class="loading" v-else-if="!material.url">
                        <i class="el-icon-loading"></i>
                      </div>
                      <i class="el-icon-error delete-icon" @click="item.materialList.splice(i, 1)"></i>
                    </div>
                  </template>
                  <i class="el-icon-delete" @click="account[1].splice(subIndex, 1)"></i>
                </div>
                <div class="footer-info">
                  <i class="iconfont icon-tupian"></i>
                  <span
                    style="padding-left:4px;cursor:pointer"
                    @click="chooseLibrary(index, subIndex, item)"
                  >素材库当中选择</span>
                  <span
                    class="total-number"
                    v-if="isValidArray(item.materialList)"
                    style="font-size:14px; color:#ccc"
                  >
                    已选素材：
                    <span
                      style="color:#00bf8a;"
                    >{{ item.materialList.filter(item => item.url).length }}</span>
                  </span>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div slot="footer">
      <el-button type="primary" @click="handleConfirm">确定</el-button>
      <el-button type="info" @click="handleCancel">取消</el-button>
    </div>
    <el-dialog
      title="选择账号"
      width="750px"
      append-to-body
      top="20px"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="handleCloseDialog"
    >
      <dy-table
        :dataList="accountList"
        :rowList="rowList"
        height="500px"
        ref="AccTable"
        refName="subTable"
        :showPagination="true"
        :isUseCheckbox="true"
        :pageSize="pageSize"
        :page="page"
        :total="total"
        :loading="loading"
        @pageChange="handlePage"
        @selection-change="handleSelectionChange"
        @row-click="handleRowClick"
      >
        <template #filter>
          <div style="line-height:50px">
            <c-input
              v-model="sendForm.channelName"
              placeholder="账号名称"
              style="width:200px;margin-right:10px"
            ></c-input>
            <c-input
              v-model="sendForm.appId"
              placeholder="appid"
              style="width:200px;margin-right:10px"
            ></c-input>
            <c-input
              v-model="sendForm.accountId"
              placeholder="账号Id"
              style="width:200px;margin-right:10px"
            ></c-input>
            <el-button @click="handlePage(1)" type="primary">搜索</el-button>
          </div>
          <div class="operations">
            <div class="principal-info">
              <span v-if="choiceList.length > 0">
                <span style="font-weight:bold">勾选账号的主体为：</span>
                <span
                  v-if="principalValue"
                >{{ isValidArray(principalList) ? principalList.find(item => item.id === principalValue).accountName : '' }}</span>
              </span>
            </div>
            <div class="required">
              <el-select
                v-model="principalValue"
                @change="handleSelectChange"
                placeholder="选择主体"
                style="width:200px"
                filterable
              >
                <el-option
                  v-for="item in principalList"
                  :key="item.id"
                  :label="item.accountName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </template>
        <template #channelName="{ row }">
          <span>{{ row.channelName }}{{ `${row.principalPart ? `（${row.principalPart.accountName}）` : ''}` }}</span>
        </template>
      </dy-table>
      <div slot="footer" class="button-list">
        <el-button type="primary" @click="handleOk">确定</el-button>
        <el-button @click="handleCloseDialog">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="素材"
      width="1400px"
      v-dialogDrag
      top="20px"
      append-to-body
      :visible.sync="showMediaDialog"
      :before-close="handleCloseMediaDialog"
    >
      <media-library :show="showMediaDialog" :mediaType="mediaType" :maxNum="60" ref="mediaLibrary">
        <div slot="footer" style="margin-top:10px;text-align: right;">
          <el-button type="primary" @click="() => { handleYes() }">确定</el-button>
          <el-button @click="handleCloseMediaDialog">取消</el-button>
        </div>
      </media-library>
    </el-dialog>

    <choose-principal-part-vue
      :show="showPrincipalDialog"
      @close="showPrincipalDialog = false"
      @confirm="confirmPrincipal"
    ></choose-principal-part-vue>
    <choose-template-vue
      :show="showTemplateDialog"
      :id="`${curInfo && curInfo.marketing ? curInfo.marketing.id : ''}`"
      :type="`${curInfo && curInfo.principalPart ? curInfo.principalPart.id : ''}`"
      @close="showTemplateDialog = false"
      @confirm="confirmTemplate"
    ></choose-template-vue>
  </el-dialog>
</template>

<script>
// ? problem? 同一个账号回显如果取消勾选，然后在勾选，则会清空之前的数据（bug）
import { nanoid } from 'nanoid'
import { isValidArray, isValidObject, deepFlatten, deduplicationProperty, getFileType, getMaterialInfo, chunk, isFalsy } from '@/assets/js/utils'
import { getWxAccountList, getSubject, addWxPromotionPage, getWxMpPages, editWxPromotionPage } from '@/api/tencent.js'
import choosePrincipalPartVue from './choose-principal-part.vue'
import chooseTemplateVue from './choose-template.vue'
import MediaLibrary from '@/views/small-plane/baidu-project/picture-library'
import _ from 'lodash'
import { addMaterialWare } from '@/api/material'
const VIDEO_TYPES = ['mp4', 'mov', 'avi', 'flv', 'mkv', 'm4a'] // 视频常见格式
const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'] // 图片常见格式
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    curTemplateInfo: {
      type: Object,
      default: () => ({})
    },
    templateId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showDialog: false,
      accountInfo: {},
      accountList: [],
      selection: [],
      chooseAccountList: [],
      choiceList: [],
      templateInfo: {},
      curInfo: {},
      page: 1,
      pageSize: 15,
      total: 0,
      loading: false,
      sendForm: {},
      isValidArray,
      isValidObject,
      VIDEO_TYPES,
      IMAGE_TYPES,
      principalValue: '',
      showPrincipalDialog: false,
      showTemplateDialog: false,
      showMediaDialog: false,
      mediaType: 'image',
      isRenderAuto: true, // ! 是否是回显自动勾选表格行
      principalList: [],
      preSelectList: [], // 选择账号列表，上一次的选中状态
      curSelectList: [], // 选择账号列表，当前的选中状态
      curInputIndex: null,
      templateName: '',
      containerLoading: false,
      mode: 1,
      rowList: [{
        label: 'id',
        prop: 'id',
        colWidth: '100'
      }, {
        label: '公众号',
        prop: 'channelName'
      }, {
        label: 'appid',
        prop: 'appid',
        colWidth: '200'
      }]
    }
  },
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          this.renderData()
        } else {
          this.page = 1
        }
      }
    },
    showDialog: {
      handler(newV) {
        if (newV) {
          this.setChoiceList()
          this.setEnterEvent()
          this.renderData()
        } else {
          document.onkeydown = null
        }
      }
    },
    curTemplateInfo: {
      handler(newV) {
        if (isValidObject(newV)) {
          // ! 回显数据
          this.echoData()
        }
      }
    }
  },
  computed: {
    accountId: {
      get() {
        return this.curInfo.marketing ? this.curInfo.marketing : ''
      },
      set() { }
    }
  },
  methods: {
    renderData() {
      getSubject({
        page: 1,
        pageSize: 9999
      }).then(res => {
        this.principalList = res.list
      })
    },
    handleChooseAccount() {
      this.showDialog = true
      this.loading = true
      this.isRenderAuto = true
      const printObj = this.principalList.find(item => item.id === this.principalValue)
      getWxAccountList({
        page: this.page,
        pageSize: this.pageSize,
        ...this.sendForm
      }).then(res => {
        this.accountList = res.list.map(item => {
          return {
            ...item,
            principalPart: this.choiceList.find(select => select.id === item.id) ? printObj || null : null
          }
        })
        this.total = res.total
      }).finally(() => {
        this.loading = false
        this.$nextTick(async () => {
          // !第一步，根据accountList回显勾选状态
          await this.echoChecked()
          // ! 第二步，根据selection来回显勾选状态
          // await this.checkSelectionList()
          // ! 进行深复制来保留当前此刻的数据
          this.preSelectList = _.cloneDeep(this.$refs.AccTable.$refs.subTable.selection)
          this.isRenderAuto = false
        })
      })
    },
    handleCancel() {
      this.$emit('close')
    },
    handleCloseDialog() {
      this.showDialog = false
    },
    handleOk() {
      if (this.choiceList.length === 0) {
        return this.$message.warning('所选账号不能为空!')
      }
      if (!this.principalValue) {
        return this.$message.warning('请选择主体')
      }
      // ! 第一步保留原来的旧数据，防止新数据id相同，但是数据不相同的问题
      let array = _.cloneDeep(this.choiceList).map(item => {
        return {
          ...item,
          targetId: item.targetId || nanoid(),
          principalPart: this.principalList.find(item => item.id === this.principalValue)
        }
      })
      // todo => 这里如果合并之前的数据，那么会重复添加，如果不合并之前的数据，则不能够添加多个主体
      let preArrayList = isValidArray(this.chooseAccountList) ? this.chooseAccountList : []
      if (preArrayList.length > 0) {
        const keyValue = this.principalList.find(item => item.id === this.principalValue).accountName
        const keyIndex = preArrayList.findIndex(item => item[0] === keyValue)
        if (preArrayList[keyIndex]) {
          const needClearItems = preArrayList[keyIndex][1].reduce((acc, b, c) => {
            // ! 如果在preArrayList里面找不到array里面的数据，则表示在这次操作当中，原来勾选的主体账号这次取消了
            if (!array.find(item => item.id !== b.id)) {
              acc.push(b)
            }
            return acc
          }, [])
          const list = preArrayList[keyIndex][1]
          needClearItems.forEach(item => {
            const index = list.findIndex(val => val.id === item.id)
            list.splice(index, 1)
          })
          preArrayList[keyIndex][1] = list
        }
        preArrayList = deepFlatten(preArrayList.map(item => item[1]))
        // * 把原来的数据放在第一部分，在下面去重的时候可以保留原来的数据
        array = [...preArrayList, ...array]
      }

      const obj = {}
      array = [...Object.entries(array.reduce((acc, b, c) => {
        if (!obj[b.principalPart.accountName]) {
          obj[b.principalPart.accountName] = []
        }
        obj[b.principalPart.accountName].push(b)
        return obj
      }, {}))]

      // !对array进行去重 还存在原有数据消失的问题(但去重)

      this.chooseAccountList = array.map(item => {
        return [item[0], deduplicationProperty(item[1], 'id')]
      })

      this.handleCloseDialog()
    },
    handleSelectionChange(select) {
      this.$nextTick(() => {
        if (this.isRenderAuto) return
        if (!isValidArray(this.preSelectList)) {
          this.preSelectList = _.cloneDeep(select)
        }
        this.curSelectList = _.cloneDeep(select)
        select.forEach((item, index) => {
          const targetIndex = this.choiceList.findIndex(i => i.id === item.id)
          if (targetIndex === -1) {
            this.choiceList = [...this.choiceList, item]
          }
        })
        // ! 如果在当前页之前的某一样被选中，之后取消选中,则要保存的数组当中取消
        // todo=>暂时有一个bug，如果全选当前页，然后取消全选，然后再勾选一个账号，就会触发bug
        // * 需要考虑全选的操作
        const needSpliceItem = this.preSelectList.filter(preItem => {
          return !this.curSelectList.find(curItem => curItem.id === preItem.id)
        })
        if (isValidArray(needSpliceItem)) {
          for (const item of needSpliceItem) {
            const Index = this.choiceList.findIndex(account => account.id === item.id)
            if (Index > -1) {
              this.choiceList.splice(Index, 1)
            }
          }
        }
        this.preSelectList = _.cloneDeep(select)
      })
    },
    // todo => choiceList 回显勾选状态需要判断是哪一个主体
    echoChecked() {
      return new Promise(resolve => {
        if (this.chooseAccountList.length > 0) {
          this.principalValue || (this.principalValue = this.principalList[0].id)
          const targetAccountList = this.chooseAccountList.find(item => item[0] === this.principalList.find(item => item.id === this.principalValue).accountName)
          if (targetAccountList) {
            for (const account of targetAccountList[1]) {
              const targetItem = this.accountList.find(item => item.id === account.id)
              if (targetItem) {
                this.$refs.AccTable.$refs.subTable.toggleRowSelection(
                  targetItem,
                  true
                )
              }
            }
          }
        }
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    checkSelectionList() {
      return new Promise(resolve => {
        if (this.choiceList.length > 0) {
          this.accountList.forEach(account => {
            const targetItem = this.choiceList.find(item => item.id === account.id)
            this.$refs.AccTable.$refs.subTable.toggleRowSelection(account, !!targetItem)
          })
        }
        this.$nextTick(() => {
          resolve()
        })
      })
    },

    checkIsNotDivideExactly() {
      return this.chooseAccountList.some(item => {
        const array = item[1]
        for (const val of array) {
          const { templateInfo: { resourceNumber }, materialList } = val
          const len = materialList.length
          if ((len % resourceNumber) !== 0) {
            return true
          }
        }
        return false
      })
    },
    combinationParams(templateName) {
      return new Promise(resolve => {
        const array = deepFlatten(this.chooseAccountList.map(item => item[1])).map(item => {
          const { resourceNumber } = item.templateInfo
          const materialIds = chunk(item.materialList.map(item => item.materialId), resourceNumber)
          return {
            id: item.marketing.id,
            type: item.principalPart.type,
            page_id: item.templateInfo.pageId,
            page_name: item.templateInfo.pageName,
            material_type: item.templateInfo.page_elements_spec_list[0].elementType === 'VIDEO' ? 2 : item.templateInfo.page_elements_spec_list[0].elementType === 'CAROUSEL' ? 3 : 1,
            wx_pages: materialIds.map(item => ({ material_ids: item }))
          }
        })
        const params = {
          content: {
            template_name: templateName,
            template_details: array
          }
        }
        resolve(params)
      })
    },

    handlePage(page) {
      this.page = page
      this.preSelectList = []
      this.handleChooseAccount()
    },
    confirmPrincipal(data) {
      this.$set(this.curInfo, 'principalPart', data)
    },
    choosePrincipalPart(val) {
      this.curInfo = val
      this.showPrincipalDialog = true
    },
    chooseTemplate(item) {
      this.curInfo = item
      this.showTemplateDialog = true
    },
    async handleSelectChange(value) {
      this.principalValue = value
      this.setChoiceList()
      if (this.choiceList.length > 0) {
        await this.checkSelectionList()
      } else {
        this.accountList.forEach(account => {
          this.$refs.AccTable.$refs.subTable.toggleRowSelection(account, false)
        })
      }
    },
    setChoiceList() {
      // ! 根据不同主体回显不同的主体下的账号列表
      if (isValidArray(this.chooseAccountList)) {
        const targetValue = this.principalList.find(item => item.id === this.principalValue)
        if (targetValue) {
          const { accountName } = targetValue
          const array = this.chooseAccountList.find(item => item[0] === accountName)
          this.choiceList = array ? array[1] : []
        } else {
          this.choiceList = []
        }
      }
    },
    confirmTemplate(data) {
      this.$set(this.curInfo, 'templateInfo', data)
    },
    localUpload(index, subIndex, value) {
      this.curInfo = value
      this.curInputIndexInfo = {
        index, subIndex
      }
      this.$refs.uploadInput[this.curInputIndexInfo.subIndex].click()
    },
    async handleLocalFileChange(e) {
      const files = [...e.target.files]
      try {
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const fileType = await getFileType(file)
          let paramsType = ''
          if (VIDEO_TYPES.includes(fileType)) {
            paramsType = 'video'
          } else if (IMAGE_TYPES.includes(fileType)) {
            paramsType = 'image'
          }
          const materialInfo = await getMaterialInfo(file, paramsType)
          const params = {
            materialType: paramsType === 'video' ? 1 : 2,
            title: materialInfo.title,
            materialResources: {
              ...materialInfo,
              sort: 1
            }
          }
          delete params.materialResources.id
          const [f] = await addMaterialWare([params])
          const { resources: [res] } = f
          // ! 前置Index

          const data = _.cloneDeep(this.chooseAccountList)
          for (const account of data) {
            const [_, list] = account
            list.forEach((item, index) => {
              if (!isValidArray(item.materialList)) {
                item.materialList = []
              }
              item.materialList.push(res || {})
            })
          }
          this.chooseAccountList = _.cloneDeep(data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.$refs.localInput.value = null
      }
    },
    async handleFileChange(e) {
      const files = [...e.target.files]
      // todo=>上传图片
      try {
        const { index, subIndex } = this.curInputIndexInfo
        // * 定义一个前置Index
        let preIndex = 0
        if (!isValidArray(this.chooseAccountList[index][1][subIndex].materialList)) {
          this.$set(this.chooseAccountList[index][1][subIndex], 'materialList', Array.from({ length: files.length }, () => ({})))
        } else {
          preIndex = this.chooseAccountList[index][1][subIndex].materialList.length
          this.$set(this.chooseAccountList[index][1][subIndex], 'materialList', [...this.chooseAccountList[index][1][subIndex].materialList, ...Array.from({ length: files.length }, () => ({}))])
        }

        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const fileType = await getFileType(file)
          let paramsType = ''
          if (VIDEO_TYPES.includes(fileType)) {
            paramsType = 'video'
          } else if (IMAGE_TYPES.includes(fileType)) {
            paramsType = 'image'
          }

          // todo=>备选方案:本地上传之后就上传到素材库
          const materialInfo = await getMaterialInfo(file, paramsType)
          const params = {
            materialType: paramsType === 'video' ? 1 : 2,
            title: materialInfo.title,
            materialResources: {
              ...materialInfo,
              sort: 1

            }
          }
          delete params.materialResources.id
          const [f] = await addMaterialWare([params])
          const { resources: [res] } = f
          this.$set(this.chooseAccountList[index][1][subIndex].materialList, preIndex + i, res || {})
        }
        this.$refs.uploadInput[this.curInputIndexInfo.subIndex].value = null
      } catch (error) {
        console.log(error)
      }
    },
    removeTab(tabIndex) {
      this.chooseAccountList.splice(+tabIndex, 1)
      this.choiceList = []
    },
    chooseLibrary(index, subIndex, val) {
      this.curInputIndexInfo = {
        index, subIndex
      }
      if (!val.templateInfo) return this.$message.warning('请先选择推广页模板!')
      this.curInfo = val
      const { templateInfo: { type } } = val
      if (['图片', '轮播图'].includes(type)) {
        this.mediaType = 'image'
      }
      if (type === '视频') {
        this.mediaType = 'video'
      }
      this.showMediaDialog = true
    },
    chooseUniteWithLibrary() {
      if (!isValidArray(this.chooseAccountList)) return this.$message.warning('请选择账号!')
      this.mediaType = ''
      this.showMediaDialog = true
    },
    localUploadFile() {
      if (!isValidArray(this.chooseAccountList)) return this.$message.warning('请选择账号!')
      this.$refs.localInput.click()
    },
    handleYes() {
      const resourceList = this.$refs.mediaLibrary.imageList
      if (this.mode === 1) {
        const { index, subIndex } = this.curInputIndexInfo
        // * 前置Index
        if (!isValidArray(this.chooseAccountList[index][1][subIndex].materialList)) {
          this.$set(this.chooseAccountList[index][1][subIndex], 'materialList', resourceList.map(item => item.resources[0]))
        } else {
          this.$set(this.chooseAccountList[index][1][subIndex], 'materialList', [...this.chooseAccountList[index][1][subIndex].materialList, ...resourceList.map(item => item.resources[0])])
        }
        // 统一操作
      } else if (this.mode === 2) {
        const data = _.cloneDeep(this.chooseAccountList)
        for (const account of data) {
          // eslint-disable-next-line no-unused-vars
          const [_, list] = account
          for (const item of list) {
            isValidArray(item.materialList) ? item.materialList = [...item.materialList, ...resourceList.map(resource => resource.resources[0])]
              : item.materialList = resourceList.map(resource => resource.resources[0])
          }
        }
        this.chooseAccountList = _.cloneDeep(data)
      }
      this.handleCloseMediaDialog()
    },
    handleCloseMediaDialog() {
      this.showMediaDialog = false
    },
    async handleConfirm() {
      if (!isValidArray(this.chooseAccountList)) {
        return this.$message.warning('所选账号不能为空!')
      }
      if (this.chooseAccountList.some(item => !isValidArray(item[1]))) {
        return this.$message.warning('单个主体下的账号不能为空!')
      }
      if (this.chooseAccountList.some(item => item[1].some(val => !val.principalPart))) {
        return this.$message.warning('请完善账号的主体内容')
      }
      if (this.chooseAccountList.some(item => item[1].some(val => !isValidArray(val.materialList)))) {
        return this.$message.warning('请完善账号素材相关的内容！')
      }
      if (this.chooseAccountList.some(item => item[1].some(val => !val.templateInfo))) {
        return this.$message.warning('请完善账号的推广页模板！')
      }
      // 将本地上传的图片首先上传到素材库当中
      // !判断每个账号的所选上传的素材总数是否能够整除推广页模板的顶部素材数量
      const flag = this.checkIsNotDivideExactly()
      if (flag) {
        return this.$message.warning('请调整素材数量与顶部素材数量的比例关系!!')
      }
      this.$prompt('请输入模板名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.isEdit ? this.templateName : ''
      }).then(({ value }) => {
        if (value) {
          this.combinationParams(value).then(params => {
            if (!this.isEdit) {
              addWxPromotionPage(params).then(() => {
                this.$message.success('新增成功!')
                this.handleCancel()
                this.resetData()
                this.$emit('freshData')
              })
            } else {
              if (this.templateName !== value) {
                addWxPromotionPage(params).then(() => {
                  this.$message.success('新增成功!')
                  this.handleCancel()
                  this.resetData()
                  this.$emit('freshData')
                })
              } else {
                editWxPromotionPage(this.templateId, params).then(() => {
                  this.$message.success('编辑成功!')
                  this.handleCancel()
                  this.resetData()
                  this.$emit('freshData')
                })
              }
            }
          })
        }
      }).catch(() => { })
    },
    echoData() {
      const { template_details, template_name } = this.curTemplateInfo
      this.templateName = template_name
      this.adjustFormatParams(_.cloneDeep(template_details))
    },
    async adjustFormatParams(details) {
      const adjustObj = {}
      this.containerLoading = true
      for (const b of details) {
        (adjustObj[b.subject.accountName] || (adjustObj[b.subject.accountName] = [])).push({
          id: b.account.id,
          targetId: b.targetId || nanoid(),
          marketing: b.account.marketing,
          channelName: b.account.channelName,
          templateInfo: await this.getTemplateInfo(b),
          principalPart: b.subject,
          materialList: deepFlatten(b.wx_pages.map(item => item.material_urls.map(val => val.material)))
        })
      }
      this.chooseAccountList = Object.entries(adjustObj)
      this.containerLoading = false
    },
    getTemplateInfo(b) {
      const TYPE_LIST = [
        {
          en: 'CAROUSEL',
          zn: '轮播图'
        },
        {
          en: 'IMAGE',
          zn: '图片'
        },
        {
          en: 'VIDEO',
          zn: '视频'
        }
      ]
      return new Promise(resolve => {
        getWxMpPages({
          page: 1,
          pageSize: 10,
          id: b.account.marketing.id,
          type: b.subject.type,
          pageId: b.page_id
        }).then(res => {
          const val = res.list[0]
          resolve({
            ...val,
            target: '推广公众号',
            type: TYPE_LIST.find(typeItem => typeItem.en === val.page_elements_spec_list[0].elementType).zn,
            resourceNumber: val.page_elements_spec_list[0].videoSpec ? 1 : val.page_elements_spec_list[0].imageSpec.imageIdList.length,
            resourcePreview: val.videoPreviewUrl || val.imagePreviewUrl
          })
        })
      })
    },
    setEnterEvent() {
      document.onkeydown = (e) => {
        if (e.key === 'Enter' && e.keyCode === 13) {
          const keys = Object.keys(this.sendForm)
          if (keys.length > 0 && keys.some(key => !isFalsy(this.sendForm[key]))) {
            this.handlePage(1)
          }
        }
      }
    },
    handleRowClick(row) {
      return this.$refs.AccTable.$refs.subTable.toggleRowSelection(row)
    },
    resetData() {
      this.mode = 1
      this.curInfo = {}
      this.sendForm = {}
      this.selection = []
      this.choiceList = []
      this.templateName = ''
      this.principalValue = ''
      this.preSelectList = []
      this.curSelectList = []
      this.mediaType = 'image'
      this.isRenderAuto = true
      this.curInputIndex = null
      this.chooseAccountList = []
    }
  },
  components: {
    choosePrincipalPartVue,
    chooseTemplateVue,
    MediaLibrary

  }
}
</script>
<style lang='scss' scoped>
/deep/ .el-tabs__content {
  max-height: 500px;
  overflow: auto !important;
}
.operation {
  @include flex(flex-start, center);
  h3 {
    width: 120px;
    flex-shrink: 0;
    text-align: right;
  }
}
.account-list {
  max-height: 700px;
  margin-top: 20px;
  overflow: auto;
  .account-item {
    @include flex(flex-start, flex-start);
    position: relative;
    .account-info {
      @include flex(flex-start, flex-start, column);
      flex-shrink: 0;
      height: 300px;
      margin-bottom: 20px;
      margin-left: 50px;
      > div {
        margin-bottom: 10px;
      }
      > span:nth-of-type(1) {
        margin-bottom: 5px;
      }
    }
    .account-material {
      flex-grow: 1;
      min-height: 300px;
      width: 500px;
      .material-list {
        border: 1px solid #f7f7f7;
        border-radius: 6px;
        padding: 10px;
        @include flex(flex-start, flex-start, row, wrap);
        position: relative;
        .el-icon-delete {
          top: -2%;
          left: -1%;
          font-size: 20px;
          border-radius: 50%;
          position: absolute;
          color: $colorRed;
          background: #fff;
          display: none;
          cursor: pointer;
        }
        &:hover {
          .el-icon-delete {
            display: block;
          }
        }
        > div {
          margin: 15px;
        }
        .add-material {
          border: 1px dashed #ccc;
          border-radius: 6px;
          height: 80px;
          width: 80px;
          @include flex(center, center);
          &:hover {
            border-color: $main;
            cursor: pointer;
            i {
              color: $main;
            }
          }
        }
        .material-item {
          height: 80px;
          width: 80px;
          border-radius: 6px;
          background: #ccc;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: inherit;
            object-fit: contain;
          }
          .loading {
            @include verticalCenter;
          }
          .delete-icon {
            position: absolute;
            top: -8%;
            left: 86%;
            border-radius: 50%;
            color: $colorRed;
            background: #fff;
            display: none;
            cursor: pointer;
            font-size: 18px;
          }
          &:hover {
            .delete-icon {
              display: block;
            }
          }
        }
      }
      .footer-info {
        margin-top: 10px;
        @include flex(flex-start, flex-start);
        i,
        span {
          vertical-align: middle;
        }
        &:hover {
          color: $main;
        }
        .total-number {
          margin-left: auto;
          margin-right: 20px;
        }
      }
    }
  }
}
.operations {
  @include flex(space-between, center);
}
</style>
